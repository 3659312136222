@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.colors.scss";

.MediaSpot {
	@include coloredComponent;

	padding: var(--spacing--5xl) 0;

	@include mq("md") {
		padding: var(--spacing--4xl) 0;
	}
}

.MediaSpot_trumpetHeading {
	@include body--lg-reg;
	padding-bottom: var(--spacing--2xl);

	@include mq("md") {
		padding-bottom: var(--spacing--4xl);
	}
}

.MediaSpot_heading {
	padding-bottom: var(--spacing--lg);

	@include mq("md") {
		padding-bottom: var(--spacing--2xl);
	}
}

.MediaSpot_imgWrapper {
	@include aspect-ratio(65, 56);

	@include mq("md") {
		@include aspect-ratio(941, 460);
	}
}

.MediaSpot_media {
	padding-bottom: var(--spacing--lg);
}

.MediaSpot_contentText {
	padding-bottom: var(--spacing--lg);
}

.MediaSpot_contentHeading {
	@include body--lg-med;
}

.MediaSpot_contentParagraph {
	padding: var(--spacing--base) 0;
}

// Theme: Kogp
[data-theme='kogp'] {
	.MediaSpot_contentHeading {
		@include body--lg-med;
		font-weight: var(--font-weight--bold);
	}
	.MediaSpot_trumpetHeading {
		font-weight: var(--font-weight--bold);
		color: var(--color-primary);
	}
}